import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  CardText,
} from "reactstrap";
import { isEmpty } from "lodash";

const ContentGallery = ({ modalMarker, closeModal, save, data, chapterId }) => {
  const [ImgData, setImgData] = useState("");
  const [Img, setImg] = useState("");
  const [errImg, setErrImg] = useState("");
  let imgref = React.createRef();

  const resetState = () => {
    setErrImg("");
    setImgData("");
    setImg("");
  };

  const setImage = (files, e) => {
    let image = { c_code_image: e.target.result };
    setImgData(files[0]);
    setImg(image);
  };

  const deleteImages = () => {
    setImg("");
    setImgData("");
  };

  const uploadImage = (e) => {
    resetState();
    const { files } = e.target;
    if (!isEmpty(files)) {
      if (files[0].size > 2000000) {
        setErrImg("File is too Big");
      } else if (!files[0].name.match(/\.(png|jpg|jpeg)$/)) {
        setErrImg("File Extension is not correct");
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = (e) => {
          setImage(files, e);
        };
      }
    } else {
      resetState();
    }
  };

  const saveEvent = () => {
    if (ImgData.id) {
      save(Img);
    } else if (ImgData) {
      const formData = new FormData();
      formData.append("image", ImgData, ImgData.name);
      formData.append("ccodeimagekey", "");
      formData.append("chapter_id", chapterId);
      save(formData);
    } else {
      closeModal();
    }
  };

  return (
    <div>
      <Modal isOpen={modalMarker} toggle={closeModal} size="xl">
        <ModalHeader toggle={closeModal}>Marker Gallery</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8">
              <Row>
                {data &&
                  data.map((mark) => {
                    //  mark?.get_c_code_image.c_code_image
                    return (
                      mark?.get_c_code_image && (
                        <Col sm="3" key={mark?.get_c_code_image?.id}>
                          <Card
                            body
                            onClick={() => {
                              setImg(mark);
                              setImgData(mark);
                            }}
                            className={`${Img === mark && "active"}`}
                          >
                            <CardTitle tag="h5" className="card-height">
                              {mark?.get_c_code_image?.filename}
                            </CardTitle>
                            <CardText>
                              <CardImg
                                bottom
                                src={mark?.get_c_code_image?.c_code_image}
                                alt="Card image"
                              />
                            </CardText>
                          </Card>
                        </Col>
                      )
                    );
                  })}
              </Row>
            </Col>
            <Col>
              <Row>
                {Img && (
                  <Card body>
                    <CardTitle tag="h5">
                      {ImgData?.get_c_code_image?.filename
                        ? ImgData?.get_c_code_image?.filename
                        : ImgData?.name}
                    </CardTitle>
                    <CardText>
                      <CardImg
                        bottom
                        src={
                          Img?.get_c_code_image
                            ? Img?.get_c_code_image?.c_code_image
                            : Img?.c_code_image
                        }
                        alt="Card image"
                      />
                    </CardText>
                    <Button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => deleteImages()}
                    >
                      Delete
                    </Button>
                  </Card>
                )}
              </Row>
              {/* <Row>
                <div className="position-relative browsing-btn">
                  <label htmlFor="upload-photo">Upload</label>
                  <input
                    id="upload-photo"
                    ref={imgref}
                    type="file"
                    name="image"
                    accept=".png , .jpeg , .jpg"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                </div>
                {errImg}
              </Row> */}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => saveEvent()}>
            Submit
          </Button>
          <Button color="primary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ContentGallery;
