import {
  LIST_BOOK_DETAILS_ERROR,
  SET_LIST_BOOK_DETAIL,
  SELECT_BOOK_ID,
  SHOW_LOADING,
  HIDE_LOADING,
  RESET_BOOK_ID,
} from "./BookActions";

const initialState = {
  bookDisable: true,
  bookId: "",
  title: "",
  isLoading: false,
  bookData: [],
  errors: {
    code: 0,
    message: "",
  },
};

const BookReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_LOADING:
      return {
        ...state,
        ...payload,
      };

    case HIDE_LOADING:
      return {
        ...state,
        ...payload,
      };

    case SET_LIST_BOOK_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        bookDisable: true,
        bookData: data,
      };
    }

    case RESET_BOOK_ID: {
      return {
        ...state,
        bookDisable: true,
        bookId: "",
      };
    }

    case SELECT_BOOK_ID: {
      return {
        ...state,
        bookDisable: false,
        bookId: payload,
      };
    }

    case LIST_BOOK_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          bookDisable: true,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default BookReducer;
