import {
  LIST_CHAPTER_DETAILS_ERROR,
  SET_LIST_CHAPTER_DETAIL,
  SELECT_CHAPTER_ID,
  SHOW_LOADING,
  HIDE_LOADING,
  RESET_CHAPTER_ID,
} from "./ChapterActions";

const initialState = {
  chapterDisable: true,
  chapterId: "",
  chapterData: [],
  title: "",
  isLoading: false,
  errors: {
    code: 0,
    message: "",
  },
};

const ChapterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_LOADING:
      return {
        ...state,
        ...payload,
      };

    case HIDE_LOADING:
      return {
        ...state,
        ...payload,
      };

    case SET_LIST_CHAPTER_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        chapterDisable: true,
        chapterData: data,
      };
    }

    case RESET_CHAPTER_ID: {
      return {
        ...state,
        chapterDisable: true,
        chapterId: "",
      };
    }

    case SELECT_CHAPTER_ID: {
      return {
        ...state,
        chapterDisable: false,
        chapterId: payload,
      };
    }

    case LIST_CHAPTER_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          chapterDisable: true,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default ChapterReducer;
