import {
  GET_UIAC_DETAIL,
  SET_UIAC_DETAIL,
  UPDATE_UIAC_DETAILS,
  UIAC_DETAILS_ERROR,
} from "./UpdateIacActions";

const initialState = {
  UiacData: [],
  errors: {
    code: 0,
    message: "",
  },
};

const UpdateIacReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_UIAC_DETAIL:
      return state.UiacData;

    case SET_UIAC_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        UiacData: data,
      };
    }
    case UPDATE_UIAC_DETAILS:
      return {
        ...state,
        UiacData: {
          ...state.UiacData,
          ...payload,
        },
      };

    case UIAC_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default UpdateIacReducer;
