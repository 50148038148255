import {
  AWS_DETAILS_ERROR,
  SET_AWS_DETAIL,
  RESET_AWS_MODAL,
  SET_AWS_MODAL_DETAIL,
} from "./AwsActions";

const initialState = {
  AwsData: [],
  AwsModalData: [],
  errors: {
    code: 0,
    message: "",
  },
};

const AwsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AWS_DETAIL: {
      const { data } = payload;
      let newData = {
        url: data?.url,
        ccodeimagekey: data?.key,
      };
      return {
        ...state,
        AwsData: newData,
      };
    }

    case RESET_AWS_MODAL: {
      return {
        ...state,
        AwsModalData: [],
        AwsData: [],
      };
    }

    case SET_AWS_MODAL_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        AwsModalData: data,
      };
    }

    case AWS_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default AwsReducer;
