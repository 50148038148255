/* eslint-disable camelcase */
import axios from 'axios';
import history from '../utils/history';

// axios.defaults.baseURL = process.env.REACT_APP_API_PATH;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (
  method,
  url,
  options = { params: {}, payload: {}, requestOptions: {} }
) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
    ...options.requestOptions,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const POST = (path, payload, requestOptions) => {
  return request('POST', path, { payload, requestOptions });
};

export const GET = (path, params) => {
  return request('GET', path, params);
};

export const PUT = (path, payload) => {
  return request('PUT', path, { payload });
};

export const PATCH = (path, payload) => {
  return request('PATCH', path, { payload });
};

export const DELETE = (path) => {
  return request('DELETE', path);
};

// axios.interceptors.request.use((request) => {
//   // Setting up headers
//   const headers = {};
//   const authString = localStorage.getItem('auth');
//   if (authString) {
//     const auth = JSON.parse(authString);
//     const { access_token } = auth;
//     if (access_token) {
//       headers.authorization = `Bearer ${access_token}`;
//     }
//   }
//   headers.Accept = 'application/json';
//   headers['Content-Type'] = 'application/json';
//   request.headers = headers;
//   return request;
// });

// /**
//  * RESPONSE INTERCEPTOR
//  */
// axios.interceptors.response.use(
//   (response) => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response.data;
//   },
//   (error) => {
//     const errorResponse = error.response;
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (errorResponse.status === 401) {
//       localStorage.removeItem('auth');
//       history.replace('/');
//     }
//     return Promise.reject(errorResponse.data);
//   }
// );
