import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { isEmpty } from 'lodash';

const LoadModelFile = ({ props }) => {
  let scene = null,
    camera = null,
    renderer = null,
    controls = null,
    container = null;
  let aspect = window.innerWidth / window.innerHeight;
  const [uploadFiles, setUploadFiles] = useState({});

  useEffect(() => {
    setUploadFiles(props);
    if (!isEmpty(uploadFiles)) init();
  }, [uploadFiles, props]);

  const init = () => {
    LoadRest();
    LoadScene();
    LoadCamera();
    LoadPointLight();
    LoadAmbientLight();
    LoadRenderer();
    LoadControl();
    // LoadFloor();
    LoadModel();
    !uploadFiles.marker && LoadGrid();

    animate();
  };

  const LoadRest = () => {
    scene = null;
    camera = null;
    renderer = null;
    controls = null;
  };

  const LoadScene = () => {
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xa9a9a9);
  };

  const LoadCamera = () => {
    camera = new THREE.PerspectiveCamera(5, aspect, 1, 1000); //fov,aspect,in,out
    camera.position.x = 0;
    camera.position.y = 5;
    camera.position.z = 5;
    scene.add(camera);
  };

  const LoadPointLight = () => {
    let light = new THREE.PointLight();
    light.intensity = 3;
    light.position.set(0, 2, 3);
    scene.add(light);
  };

  const LoadAmbientLight = () => {
    let ambientLight = new THREE.AmbientLight();
    scene.add(ambientLight);
  };

  const LoadControl = () => {
    controls = new OrbitControls(camera, renderer.domElement);
    controls.update();
    controls.addEventListener('change', render);
  };

  const LoadGrid = () => {
    let size = 8;
    let boxes = 8;
    let gridControl = new THREE.GridHelper(size, boxes);
    gridControl.rotation.x = Math.PI / 3.6;

    scene.add(gridControl);
  };

  const LoadRenderer = () => {
    renderer = new THREE.WebGLRenderer({ antialias: true });
    // renderer.setSize(window.innerWidth / 5.1, window.innerHeight / 3);
    document.body.appendChild(renderer.domElement);
    container = document.querySelector('#ThreeJs');
    if (container && container.childElementCount > 0) {
      container.innerHTML = '';
    }
    container.appendChild(renderer.domElement);

    window.addEventListener('resize', () => {
      // renderer.setSize(width / 5.4, height / 4);
    });
  };

  // const LoadFloor = () => {
  //   const textureLoader = new THREE.TextureLoader();
  //   const t1 = textureLoader.load(uploadFiles?.marker);
  //   const groundGeometry = new THREE.PlaneBufferGeometry(20, 20, 10, 10);
  //   const groundMaterial = new THREE.MeshBasicMaterial({
  //     map: t1,
  //   });
  //   const ground = new THREE.Mesh(groundGeometry, groundMaterial);
  //   ground.rotation.x = -Math.PI / 4.5;
  //   ground.rotation.y = 0;
  //   ground.rotation.z = 0;
  //   ground.scale.x = 0.4;
  //   ground.scale.y = 0.4;
  //   ground.scale.z = 0.4;
  //   ground.name = "Ground";

  //   scene.add(ground);
  // };

  const LoadModel = () => {
    const loadedMaterials = [];
    const texture = new THREE.Texture();
    const manager = new THREE.LoadingManager();
    const imgLoader = new THREE.ImageLoader(manager);
    // const modelLoader = new FBXLoader(manager);

    if (uploadFiles?.type.toLowerCase() === 'fbx') {
      const manager = new THREE.LoadingManager();
      const fbxLoader = new FBXLoader();
      const texture = new THREE.Texture();
      const loader = new THREE.ImageLoader(manager);

      // fbxLoader.load(uploadFiles.url, (fbx) => {
      //   fbx.receiveShadow = true;
      //   fbx.castShadow = true;
      //   fbx.matrixAutoUpdate = true;
      //   fbx.scale.x = uploadFiles?.scale?.x || 1;
      //   fbx.scale.y = uploadFiles?.scale?.y || 1;
      //   fbx.scale.z = uploadFiles?.scale?.z || 1;
      //   fbx.position.x = uploadFiles?.position?.x || 0;
      //   fbx.position.y = uploadFiles?.position?.y || 0;
      //   fbx.position.z = uploadFiles?.position?.z || 0;
      //   fbx.rotation.x = uploadFiles?.rotation?.x || 0;
      //   fbx.rotation.y = uploadFiles?.rotation?.y || 0;
      //   fbx.rotation.z = uploadFiles?.rotation?.z || 0;
      //   fbx.name = uploadFiles?.name;
      //   fbx.updateMatrix();
      //   console.log('fbx', fbx);

      //   scene.add(fbx);
      // });

      const mtlLoader = new MTLLoader(manager);
      mtlLoader.load(uploadFiles?.mtl, (materials) => {
        materials.preload();
        loadedMaterials.push(materials);
      });
      for (let i = 0; i < uploadFiles.arrOfTexture.length; i++) {
        imgLoader.load(uploadFiles.arrOfTexture[i].ar_content_files, (img) => {
          if (img) {
            texture.image = img;
            texture.mapping = THREE.UVMapping;
            texture.needsUpdate = true;
          }
        });
      }
      manager.onLoad = () => {
        fbxLoader.load(
          uploadFiles.url,
          (fbx) => {
            // fbx.traverse((child) => {
            //   if (child instanceof THREE.Mesh) {
            //     child.material =
            //       child.material || new THREE.MeshPhongMaterial();
            //     child.material['map'] = texture;
            //   }
            // });
            fbx.receiveShadow = true;
            fbx.castShadow = true;
            fbx.matrixAutoUpdate = true;
            fbx.scale.x = uploadFiles?.scale?.x || 1;
            fbx.scale.y = uploadFiles?.scale?.y || 1;
            fbx.scale.z = uploadFiles?.scale?.z || 1;
            fbx.position.x = uploadFiles?.position?.x || 0;
            fbx.position.y = uploadFiles?.position?.y || 0;
            fbx.position.z = uploadFiles?.position?.z || 0;
            fbx.rotation.x = uploadFiles?.rotation?.x || 0;
            fbx.rotation.y = uploadFiles?.rotation?.y || 0;
            fbx.rotation.z = uploadFiles?.rotation?.z || 0;
            fbx.name = uploadFiles?.name;
            fbx.updateMatrix();

            scene.add(fbx);
          },
          (xhr) => {
            // console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
            // if ((xhr.loaded / xhr.total) * 100 === 100) {
            //   props.hideLoading();
            // } else {
            //   props.showLoading('Loading');
            // }
          },
          (error) => {
            // console.log('An error happened');
          }
        );
      };
    }
    if (uploadFiles?.type.toLowerCase() === 'obj') {
      const mtlLoader = new MTLLoader(manager);
      mtlLoader.load(uploadFiles?.mtl, (materials) => {
        materials.preload();
        loadedMaterials.push(materials);
      });
      for (let i = 0; i < uploadFiles.arrOfTexture.length; i++) {
        imgLoader.load(uploadFiles.arrOfTexture[i].ar_content_files, (img) => {
          if (img) {
            texture.image = img;
            texture.mapping = THREE.UVMapping;
            texture.needsUpdate = true;
          }
        });
      }
      manager.onLoad = () => {
        if (loadedMaterials.length > 0) {
          const objLoader = new OBJLoader();
          objLoader.setMaterials(loadedMaterials[0]);
          objLoader.load(uploadFiles?.url, (object) => {
            object.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.material =
                  child.material || new THREE.MeshPhongMaterial();
                child.material['map'] = texture;
              }
            });
            object.receiveShadow = true;
            object.castShadow = true;
            object.name = uploadFiles?.name;
            object.matrixAutoUpdate = true;
            object.updateMatrix();
            object.scale.x = uploadFiles?.scale?.x || 1;
            object.scale.y = uploadFiles?.scale?.y || 1;
            object.scale.z = uploadFiles?.scale?.z || 1;
            object.position.x = uploadFiles?.position?.x || 1;
            object.position.y = uploadFiles?.position?.y || 1;
            object.position.z = uploadFiles?.position?.z || 1;
            object.rotation.x = uploadFiles?.rotation?.x || 1;
            object.rotation.y = uploadFiles?.rotation?.y || 1;
            object.rotation.z = uploadFiles?.rotation?.z || 1;
            scene.add(object);
          });
        }
      };
    }
  };

  const animate = () => {
    requestAnimationFrame(animate);
    scene.updateMatrixWorld();
    render();
  };

  const render = () => {
    renderer.render(scene, camera);
  };

  return <div id='ThreeJs' />;
};

export default LoadModelFile;
