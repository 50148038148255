export const SHOW_TOASTER = "SHOW_TOASTER";
export const HIDE_TOASTER = "HIDE_TOASTER";

export const showToaster = (title, type) => {
  return {
    type: SHOW_TOASTER,
    payload: { title, type, isLoading: true },
  };
};

export const hideToaster = () => {
  return {
    type: HIDE_TOASTER,
    payload: { title: "", isLoading: false },
  };
};
