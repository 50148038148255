import {
  AWS_DETAILS_ERROR,
  RESET_AWS_MODAL,
  SET_AWS_DETAIL,
  SET_AWS_MODAL_DETAIL,
} from "./AwsActions";

import { showLoading, hideLoading } from "../loader/LoaderActions";
import axios from "axios";
import { headerConfig } from "../../utils/constants";

// set initial data of Aws details through an API
export const setAwsDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_AWS_DETAIL,
      payload: data,
    });
  };
};

// set initial data of Aws details through an API
export const resetAwsDetails = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_AWS_MODAL,
    });
  };
};

export const setAwsModalDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_AWS_MODAL_DETAIL,
      payload: data,
    });
  };
};

// handler for all errors and inform to the store
export const AwsDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: AWS_DETAILS_ERROR,
      payload: error,
    });
  };
};

/**
 * GET Aws DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchModelAwsDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/upload3dmodeltos3`;
    dispatch(showLoading("Loading..."));
    return axios
      .post(url, payload, headerConfig())
      .then((res) => {
        dispatch(hideLoading());
        dispatch(setAwsModalDetails(res.data));
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(AwsDetailsErrorHandler(err));
      });
  };
};

/**
 * Add Aws details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchAddAwsDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/uploadtos3`;
    dispatch(showLoading("Loading..."));
    return axios
      .post(url, payload, headerConfig())
      .then((res) => {
        dispatch(hideLoading());
        dispatch(setAwsDetails(res.data));
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(AwsDetailsErrorHandler(err));
      });
  };
};
