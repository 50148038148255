import { combineReducers } from "redux";
import SeriesReducer from "./series/SeriesReducer";
import BookReducer from "./books/BookReducer";
import ChapterReducer from "./chapter/ChapterReducer";
import MarkerModelReducer from "./markerModel/MarkerModelReducer";
import SaveIacReducer from "./saveIac/SaveIacReducer";
import UpdateIacReducer from "./updateIac/UpdateIacReducer";
import AwsReducer from "../aws/AwsReducer";
import LoadingReducer from "../loader/LoaderReducer";
import ToasterReducer from "../Toaster/ToasterReducer";

const AdminReducer = combineReducers({
  SeriesReducer: SeriesReducer,
  BookReducer,
  ChapterReducer,
  MarkerModelReducer,
  AwsReducer,
  SaveIacReducer,
  UpdateIacReducer,
  LoadingReducer,
  ToasterReducer,
});

export default AdminReducer;
