import {
  GET_SAVE_IAC_DETAIL,
  SAVE_IAC_DETAILS_ERROR,
  SET_SAVE_IAC_DETAIL,
  UPDATE_SAVE_IAC_DETAILS,
  MODAL_CLOSE,
} from "./SaveIacActions";
const initialState = {
  updateModalId: "",
  SiacData: [],
  modalStatus: false,
  errors: {
    code: 0,
    message: "",
  },
};

const SaveIacReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SAVE_IAC_DETAIL:
      return state.SiacData;

    case SET_SAVE_IAC_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        SiacData: data,
        modalStatus: true,
        updateModalId: data.model_id,
      };
    }

    case UPDATE_SAVE_IAC_DETAILS:
      return {
        ...state,
        SiacData: {
          ...state.SiacData,
          ...payload,
        },
      };

    case SAVE_IAC_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };

    case MODAL_CLOSE: {
      return {
        ...state,
        modalStatus: false,
      };
    }

    default:
      return state;
  }
};

export default SaveIacReducer;
