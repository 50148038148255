import React from 'react';
import { Row, Container, Col } from 'reactstrap';

const Axis = ({ x, y, z, setX, setY, setZ }) => {
  return (
    <div>
      <Container>
        <Row className='row-spacing'>
          <Col xs='4' className='axis-label-name'>
            X-Axis
          </Col>
          <Col>
            <input
              type='number'
              //step={0.02}
              className='form-control '
              value={x}
              onChange={(e) => setX(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='row-spacing'>
          <Col xs='4' className='axis-label-name'>
            Y-Axis
          </Col>
          <Col>
            <input
              type='number'
              // step={0.02}
              className='form-control '
              value={y}
              onChange={(e) => setY(e.target.value)}
            />
          </Col>
        </Row>
        <Row className='row-spacing'>
          <Col xs='4' className='axis-label-name'>
            Z-Axis
          </Col>
          <Col>
            <input
              type='number'
              // step={0.02}
              className='form-control '
              value={z}
              onChange={(e) => setZ(e.target.value)}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Axis;
