import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AdminController from './components/admin/Admin';
import AppLoader from './components/loader/AppLoader';
const PreLoadingMessage = () => <h1>Loading...</h1>;

const routesConfig = (props) => {
  return (
    <div>
      {props.load.isLoading && <AppLoader title={props.load.title} />}
      <Switch>
        <Suspense fallback={PreLoadingMessage}>
          <Route component={AdminController} path={'/'} exact />
          <Route component={AdminController} path={'/?hash:auth&id:id'} exact />
        </Suspense>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    series: state.admin.SeriesReducer,
    book: state.admin.BookReducer,
    chapter: state.admin.ChapterReducer,
    modelData: state.admin.MarkerModelReducer,
    aws: state.admin.AwsReducer,
    saveIac: state.admin.SaveIacReducer,
    load: state.admin.LoadingReducer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(routesConfig);
