import {
  LIST_BOOK_DETAILS_ERROR,
  SET_LIST_BOOK_DETAIL,
  SELECT_BOOK_ID,
  HIDE_LOADING,
  SHOW_LOADING,
  RESET_BOOK_ID,
} from "./BookActions";

import { GET, POST, PUT } from '../../../services/rest.service';
import { headerConfig } from '../../../utils/constants';
import axios from 'axios';

export const showLoading = (title) => {
  return {
    type: SHOW_LOADING,
    payload: { title, isLoading: true },
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
    payload: { title: '', isLoading: false },
  };
};

// set initial data of Book details through an API
export const setBookDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_LIST_BOOK_DETAIL,
      payload: data,
    });
  };
};

// handler for all errors and inform to the store
export const BookDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: LIST_BOOK_DETAILS_ERROR,
      payload: error,
    });
  };
};

export const setBookId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_BOOK_ID,
      payload: id,
    });
  };
};

export const ResetBookId = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_BOOK_ID,
    });
  };
};


/* SERVER-SIDE APIs */
/**
 * GET Book DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchBookDetails = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/booksbyseries/${id}`;
    dispatch(showLoading('Loading...'));
    return axios
      .get(url, headerConfig())
      .then((res) => {
        dispatch(hideLoading());
        dispatch(setBookDetails(res.data));
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(BookDetailsErrorHandler(err));
      });
  };
};
