import {
  LIST_CHAPTER_DETAILS_ERROR,
  SET_LIST_CHAPTER_DETAIL,
  SELECT_CHAPTER_ID,
  HIDE_LOADING,
  SHOW_LOADING,
  RESET_CHAPTER_ID,
} from "./ChapterActions";

import { GET, POST, PUT } from "../../../services/rest.service";
import { headerConfig } from "../../../utils/constants";
import axios from "axios";

export const showLoading = (title) => {
  return {
    type: SHOW_LOADING,
    payload: { title, isLoading: true },
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
    payload: { title: "", isLoading: false },
  };
};

// set initial data of Chapter details through an API
export const setChapterDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_LIST_CHAPTER_DETAIL,
      payload: data,
    });
  };
};

// handler for all errors and inform to the store
export const ChapterDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: LIST_CHAPTER_DETAILS_ERROR,
      payload: error,
    });
  };
};

export const setChapterId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_CHAPTER_ID,
      payload: id,
    });
  };
};

export const ResetChapterId = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CHAPTER_ID,
    });
  };
};
/* SERVER-SIDE APIs */
/**
 * GET Chapter DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchChapterDetails = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/chaptersbybook/${id}`;
    dispatch(showLoading("Loading..."));
    return axios
      .get(url, headerConfig())
      .then((res) => {
        dispatch(hideLoading());
        dispatch(setChapterDetails(res.data));
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(ChapterDetailsErrorHandler(err));
      });
  };
};
