import {
  GET_UIAC_DETAIL,
  SET_UIAC_DETAIL,
  UPDATE_UIAC_DETAILS,
  UIAC_DETAILS_ERROR,
} from "./UpdateIacActions";

import { GET, POST, PUT } from "../../../services/rest.service";
import { headerConfig } from "../../../utils/constants";
import axios from "axios";
import { showToaster } from "../../Toaster/ToasterActions";

export const getUIacDetails = () => {
  return (dispatch) => {
    dispatch({
      type: GET_UIAC_DETAIL,
    });
  };
};

// set initial data of Iac details through an API
export const setUIacDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_UIAC_DETAIL,
      payload: data,
    });
  };
};

// update new Iac details after successful updation
export const updateUIacDetails = (updatedData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_UIAC_DETAILS,
      payload: updatedData,
    });
  };
};

// handler for all errors and inform to the store
export const UIacDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: UIAC_DETAILS_ERROR,
      payload: error,
    });
  };
};

/* SERVER-SIDE APIs */
/**
 * GET Iac DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchUIacDetails = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/show3dmodel/${id}`;

    return axios
      .get(url, headerConfig())
      .then((res) => {
        res.data.code === 200
          ? dispatch(setUIacDetails(res.data)) &&
            dispatch(showToaster(res.data?.message, "success"))
          : dispatch(showToaster(res.data?.message, "error"));
      })
      .catch((err) => {
        dispatch(UIacDetailsErrorHandler(err));
      });
  };
};

/**
 * Update details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchUUpdateIacDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/Iac`;
    return PUT(url, payload)
      .then((res) => {
        dispatch(setUIacDetails(res));
      })
      .catch((err) => {
        dispatch(UIacDetailsErrorHandler(err));
      });
  };
};

/**
 * Add Iac details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchUAddIacDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/uploadtos3`;
    return POST(url, payload, headerConfig())
      .then((res) => {
        dispatch(setUIacDetails(res));
      })
      .catch((err) => {
        dispatch(UIacDetailsErrorHandler(err));
      });
  };
};
