import {
  GET_FILES_DETAIL,
  FILES_DETAILS_ERROR,
  SET_FILES_DETAIL,
  UPDATE_FILES_DETAILS,
} from "./MarkerModelActions";

import { GET, POST, PUT } from "../../../services/rest.service";
import axios from "axios";
import { headerConfig } from "../../../utils/constants";

export const getFilesDetails = () => {
  return (dispatch) => {
    dispatch({
      type: GET_FILES_DETAIL,
    });
  };
};

// set initial data of Files details through an API
export const setFilesDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILES_DETAIL,
      payload: data,
    });
  };
};

// update new Files details after successful updation
export const updateFilesDetails = (updatedData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FILES_DETAILS,
      payload: updatedData,
    });
  };
};

// handler for all errors and inform to the store
export const FilesDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FILES_DETAILS,
      payload: error,
    });
  };
};

/* SERVER-SIDE APIs */
/**
 * GET Files DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchFilesDetails = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/chapter/getfiles/${id}`;
    return axios
      .get(url, headerConfig())
      .then((res) => {
        dispatch(setFilesDetails(res.data));
      })
      .catch((err) => {
        dispatch(FilesDetailsErrorHandler(err));
      });
  };
};

/**
 * Update details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchUpdateFilesDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/Files`;
    return PUT(url, payload)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_PATH}/Files`;
        return GET(url).then((res) => {
          dispatch(setFilesDetails(res));
        });
      })
      .catch((err) => {
        dispatch(FilesDetailsErrorHandler(err));
      });
  };
};

/**
 * Add Files details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchAddFilesDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/Files`;
    return POST(url, payload)
      .then((res) => {
        const url = `${process.env.REACT_APP_API_PATH}/Files`;
        return GET(url).then((res) => {
          dispatch(setFilesDetails(res));
        });
      })
      .catch((err) => {
        dispatch(FilesDetailsErrorHandler(err));
      });
  };
};
