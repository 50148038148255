import { HIDE_TOASTER, SHOW_TOASTER } from "./ToasterActions";

const initialState = {
  title: "",
  type: "",
  isLoading: false,
};

const ToasterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_TOASTER: {
      return {
        ...payload,
      };
    }

    case HIDE_TOASTER:
      return initialState;

    default:
      return state;
  }
};

export default ToasterReducer;
