import {
  GET_FILES_DETAIL,
  FILES_DETAILS_ERROR,
  SET_FILES_DETAIL,
  UPDATE_FILES_DETAILS,
} from './MarkerModelActions';

const initialState = {
  modelData: [],
  errors: {
    code: 0,
    message: '',
  },
};

const MarkerModelReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FILES_DETAIL:
      return state.modelData;

    case SET_FILES_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        modelData: data,
      };
    }
    case UPDATE_FILES_DETAILS:
      return {
        ...state,
        modelData: {
          ...state.modelData,
          ...payload,
        },
      };

    case FILES_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default MarkerModelReducer;
