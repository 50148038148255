import {
  LIST_SERIES_DETAILS_ERROR,
  SET_LIST_SERIES_DETAIL,
  SELECT_SERIES_ID,
  HIDE_LOADING,
  SHOW_LOADING,
  RESET_SERIES_ID,
} from "./SeriesActions";

import { GET, POST, PUT } from "../../../services/rest.service";
import axios from "axios";
import { headerConfig } from "../../../utils/constants";
import { showToaster } from "../../Toaster/ToasterActions";

export const showLoading = (title) => {
  return {
    type: SHOW_LOADING,
    payload: { title, isLoading: true },
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
    payload: { title: "", isLoading: false },
  };
};

// set initial data of Series details through an API
export const setSeriesDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_LIST_SERIES_DETAIL,
      payload: data,
    });
  };
};

// handler for all errors and inform to the store
export const SeriesDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: LIST_SERIES_DETAILS_ERROR,
      payload: error,
    });
  };
};

export const setSeriesId = (id) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_SERIES_ID,
      payload: id,
    });
  };
};

export const ResetSeriesId = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SERIES_ID,
    });
  };
};

export const getLoginTokenDetails = (data) => {
  localStorage.setItem("auth", JSON.stringify(data.token));
};

/* SERVER-SIDE APIs */

export const fetchTokenDetails = () => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/user/loginbyhash`;
    let hash = "NPxJZ0fDd4ueGeB81EVrAvU1fyMNXlzvIFxd4IG4YRm0D73SG0NiRzSyynPV";
    const formData = new FormData();
    formData.append("hash", hash);
    return POST(url, formData)
      .then((res) => {
        res.data.success
          ? dispatch(getLoginTokenDetails(res.data?.data)) &&
            dispatch(showToaster(res.data?.message, "success"))
          : dispatch(showToaster(res.data?.message, "error"));
      })
      .catch((err) => {
        dispatch(SeriesDetailsErrorHandler(err));
      });
  };
};

export const fetchTokenDetailsHash = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/user/loginbyhash`;
    const formData = new FormData();
    formData.append("hash", payload);
    return POST(url, formData)
      .then((res) => {
        res.data.success
          ? dispatch(getLoginTokenDetails(res.data?.data)) &&
            dispatch(showToaster(res.data?.message, "success"))
          : dispatch(showToaster(res.data?.message, "error")) &&
            localStorage.removeItem("auth");
      })
      .catch((err) => {
        dispatch(SeriesDetailsErrorHandler(err));
      });
  };
};
/**
 * GET Series DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchSeriesDetails = () => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/series`;
    dispatch(showLoading("Loading..."));
    return axios
      .get(url, headerConfig())
      .then((res) => {
        dispatch(hideLoading());
        dispatch(setSeriesDetails(res.data));
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(SeriesDetailsErrorHandler(err));
      });
  };
};
