import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import LoadModelFile from '../../assets/LoadFiles/LoadModelFile';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import ModelGalleryJson from '../../assets/jsonData/modelGalyJson';
import {
  Row,
  Col,
  Card,
  CardImg,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  CardText,
  CardBody,
} from 'reactstrap';
import { isEmpty } from 'lodash';

const ModelGallery = ({ model3d, closeModal, save, data, api, awsData }) => {
  const [ImgData, setImgData] = useState();
  const [Img, setImg] = useState();
  const [errImg, setErrImg] = useState();
  const [toggle, setToggle] = useState(false);

  const [arr, setArr] = useState([]);
  const [murl, setMurl] = useState('');
  const [mname, setMname] = useState('');
  const [uname, setUname] = useState('');
  const [uurl, setUurl] = useState('');
  const [uKey, setUKey] = useState('');
  const [mKey, setMKey] = useState('');

  const [modelType, setModelType] = useState('');

  const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
  const [scale, setScale] = useState({ x: 1, y: 1, z: 1 });

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    if (uname && uurl) {
      setImg({
        name: uname,
        type: uurl
          ?.split('/')
          .pop()
          .split('?')[0]
          .split('.')
          .pop()
          .toLowerCase(),
        marker: '/car/textures/c4d13e1ef5c20e49d7206aefff123f19--no-signa.jpg',
        arrOfTexture: arr,
        url: uurl,
        mtl: murl,
        // position,
        // rotation,
        // scale,
      });
    }
  }, [arr, uurl, murl, uname]);

  useEffect(() => {
    if (!isEmpty(awsData) && toggle) {
      SetUrl(awsData);
    }
    if (isEmpty(awsData) && toggle) {
      resetState();
      setErrImg('There is some error while uploading please Upload Again !!!');
    }
  }, [awsData, toggle]);

  const resetState = () => {
    setArr([]);
    setMurl('');
    setMname('');
    setUname('');
    setUurl('');
    setErrImg('');
    setImgData('');
    setImg('');
    setModelType('');
  };

  const SetUrl = (awsFiles) => {
    setErrImg('');
    let arr = [];
    if (awsFiles.length > 0) {
      for (let j = 0; j < awsFiles.length; j++) {
        let URlExtention = awsFiles[j].url
          .split('/')
          .pop()
          .split('?')[0]
          .split('.')
          .pop()
          .toLowerCase();
        if (URlExtention === 'fbx') {
          setUname(awsFiles[j].key);
          setUurl(awsFiles[j].url);
          setUKey(awsFiles[j].key);
        }
        if (URlExtention === 'obj') {
          setUname(awsFiles[j].key);
          setUurl(awsFiles[j].url);
          setUKey(awsFiles[j].key);
        }
        if (
          URlExtention === 'png' ||
          URlExtention === 'jpeg' ||
          URlExtention === 'jpg'
        ) {
          arr.push({
            ar_content_files: awsFiles[j].url,
            arcontentfilekey: awsFiles[j].key,
          });
        }
        if (URlExtention === 'mtl') {
          setMname(awsFiles[j].key);
          setMurl(awsFiles[j].url);
          setMKey(awsFiles[j].key);
        }
      }
      setArr(arr);
    }
  };

  const deleteImages = () => {
    setImg('');
    setImgData('');
  };

  const convertToModel = (model) => {
    setErrImg('');
    setToggle(false);

    resetState();
    let newarr = [];
    let value = model?.getcontentgallery?.getcontentgalleryfile;
    if (isEmpty(value)) {
      setErrImg('There is some error while uploading please Upload Again !!!');
    }
    if (value.length) {
      for (let j = 0; j < value.length; j++) {
        let tmp = value[j].ar_content_files;
        let extention = tmp
          ?.split('/')
          .pop()
          .split('?')[0]
          .split('.')
          .pop()
          .toLowerCase();
        if (extention === 'fbx') {
          setUname(value[j].arcontentfilekey);
          setUKey(value[j].arcontentfilekey);
          setUurl(tmp);
        }
        if (extention === 'obj') {
          setUname(value[j].arcontentfilekey);
          setUKey(value[j].arcontentfilekey);
          setUurl(tmp);
        }
        if (
          extention === 'png' ||
          extention === 'jpeg' ||
          extention === 'jpg'
        ) {
          let data = {};
          data['ar_content_files'] = value[j].ar_content_files;
          data['arcontentfilekey'] = value[j].arcontentfilekey;
          newarr.push(data);
        }
        if (extention === 'mtl') {
          setMurl(tmp);
          setUname(value[j].arcontentfilekey);
          setUKey(value[j].arcontentfilekey);
        }
      }
      setMname(model.getcontentgallery.filename?.split('.')[0]);
      setUname(model.getcontentgallery.filename?.split('.')[0]);
      setArr(newarr);
    }
  };

  const uploadModelZip = (e) => {
    setToggle(false);
    setErrImg('');
    if (!isEmpty(e.target.files)) {
      let ZIPFile = e.target.files[0];
      const formData = new FormData();
      formData.append('zip_file', ZIPFile, ZIPFile?.name);
      api(formData);
      setToggle(true);
    } else {
      resetState();
    }
  };

  const submitData = () => {
    if (!isEmpty(uurl)) {
      save({
        arrOfTexture: arr || Img?.texture,
        mtl: {
          name: { name: mname } || { name: Img?.name },
          url: murl || Img?.mtl,
          arcontentfilekey: mKey || '',
        },
        url: {
          name: { name: uname } || {
            name: Img?.name + '.' + Img?.url?.split('.').pop().toLowerCase(),
          },
          url: uurl || Img?.url,
          arcontentfilekey: uKey || '',
        },
        modelType:
          modelType ||
          uurl?.split('/').pop().split('?')[0].split('.').pop().toLowerCase(),
        position,
        rotation,
        scale,
      });
    } else {
      closeModal();
    }
  };

  return (
    <div>
      <div id='Canvas' />
      <Modal isOpen={model3d} toggle={closeModal} size='xl'>
        <ModalHeader toggle={closeModal}>AR Gallery</ModalHeader>
        <ModalBody>
          <Row>
            <Col md='8'>
              <Row>
                {data &&
                  data.map((img, i) => {
                    return (
                      <Col sm='3' key={i}>
                        <Card
                          body
                          onClick={() => convertToModel(img)}
                          className={`${Img === img && 'active'}`}
                        >
                          <CardTitle tag='h5' className='card-height'>
                            {img.getcontentgallery.filename?.split('.')[0]}
                          </CardTitle>
                          <CardBody>
                            <CardImg
                              bottom
                              src={'/Default_Image_Thumbnail.png'}
                              alt='Card image'
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
            <Col md='4'>
              <Row>
                {Img && (
                  <Card body>
                    <CardTitle tag='h5'>{Img.name}</CardTitle>
                    <CardBody>
                      <LoadModelFile props={Img} />
                    </CardBody>
                    <Button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => deleteImages()}
                    >
                      Delete
                    </Button>
                  </Card>
                )}
              </Row>
              {/* <Row>
                <div className='position-relative browsing-btn'>
                  <label htmlFor='upload-photo'>Upload</label>
                  <input
                    id='upload-photo'
                    type='file'
                    name='image'
                    accept='.zip'
                    onChange={(e) => uploadModelZip(e)}
                  />
                </div>
              </Row> */}
              {errImg && <span style={{ color: 'red' }}>{errImg}</span>}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => submitData()}>
            Submit
          </Button>
          <Button
            color='primary'
            onClick={() => {
              resetState();
              closeModal();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModelGallery;
