export const headerConfig = () => {
  return {
    headers: {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("auth")),
      "Content-Type": "multipart/form-data",
      // "Access-Control-Allow-Origin": "*",
    },
  };
};
// 'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept',
// 'Access-Control-Allow-Origin': '*',
