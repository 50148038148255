import {
  LIST_SERIES_DETAILS_ERROR,
  SET_LIST_SERIES_DETAIL,
  SELECT_SERIES_ID,
  SHOW_LOADING,
  HIDE_LOADING,
  RESET_SERIES_ID,
} from "./SeriesActions";

const initialState = {
  seriesDisable: true,
  seriesId: "",
  seriesData: [],
  title: "",
  isLoading: false,
  errors: {
    code: 0,
    message: "",
  },
};

const SeriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_LOADING:
      return {
        ...state,
        ...payload,
      };

    case HIDE_LOADING:
      return {
        ...state,
        ...payload,
      };

    case SET_LIST_SERIES_DETAIL: {
      const { data } = payload;
      return {
        ...state,
        seriesDisable: true,
        seriesData: data,
      };
    }

    case RESET_SERIES_ID: {
      return {
        ...state,
        seriesDisable: true,
        seriesData: "",
      };
    }

    case SELECT_SERIES_ID:
      return {
        ...state,
        seriesDisable: false,
        seriesId: payload,
      };

    case LIST_SERIES_DETAILS_ERROR:
      return {
        ...state,
        seriesDisable: true,
        errors: {
          ...state.errors,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default SeriesReducer;
