/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import Axis from './Axis';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import ContentGallery from '../gallery/MarkerGallery';
import ModelGallery from '../gallery/ModelGallery';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { showLoading, hideLoading } from '../../redux/loader/LoaderActions';
import { connect } from 'react-redux';
import { MathUtils } from 'three';
import {
  fetchSeriesDetails,
  fetchTokenDetails,
  setSeriesId,
  ResetSeriesId,
  fetchTokenDetailsHash,
} from '../../redux/admin/series/SeriesActionCreators';

import {
  fetchBookDetails,
  setBookId,
  ResetBookId,
} from '../../redux/admin/books/BookActionCreator';

import {
  fetchChapterDetails,
  setChapterId,
  ResetChapterId,
} from '../../redux/admin/chapter/ChapterActionCreator';
import { fetchFilesDetails } from '../../redux/admin/markerModel/MarkerModelActionCreator';
import {
  fetchAddIacDetails,
  fetchUpdateIacDetails,
  ModalCloseHandler,
  setSaveIacDetails,
} from '../../redux/admin/saveIac/SaveIacActionCreator';
import {
  fetchUAddIacDetails,
  fetchUIacDetails,
} from '../../redux/admin/updateIac/UpdateIacActionCreators';
import {
  fetchAddAwsDetails,
  fetchModelAwsDetails,
  resetAwsDetails,
} from '../../redux/aws/AwsActionCreator';
import { hideToaster } from '../../redux/Toaster/ToasterActions';

import { clone, cloneDeep, isEmpty } from 'lodash';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

let meshs = [];
let transformControl,
  container,
  scene,
  floor,
  activeObjectuuid = {};

const AdminController = (props) => {
  let camera = THREE.PerspectiveCamera,
    renderer = THREE.WebGLRenderer,
    orbitControl = OrbitControls,
    gridControl = THREE.GridHelper,
    mouse = THREE.Vector2,
    newMesh = THREE.Mesh;

  // const [activeObjectuuid, setActiveObjectuuid] = useState({});

  const [data, setData] = useState({
    iacContentObject: {
      iacName: '',
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    filename: '',
  });
  const [iacModelName, setIacModelName] = useState();
  const [uploadedThreeDModel, setUploadedThreeDModel] = useState([]);
  const [markerImg, setMarkerImg] = useState();
  const [modalMarker, setModalMarker] = useState(false);
  const [model3d, setModel3d] = useState(false);
  const [control, setControl] = useState({
    isTranslate: true,
    isRotate: false,
    isScale: false,
  });

  const [seriesId, setSeriesId] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [bookId, setBookId] = useState('');
  const [updateIacId, setUpdateIacId] = useState();
  // const [tmpAwsArrayMarker, setTmpAwsArrayMarker] = useState([]);

  useEffect(() => {
    const { fetchTokenDetails, fetchSeriesDetails } = props;
    if (props.saveIac.updateModalId) {
      setUpdateIacId(props.saveIac.updateModalId);
    }
    if (props.location.search) {
      searchUrlisThere();
    } else {
      localStorage.removeItem('hash');
      fetchTokenDetails();
    }
    setTimeout(() => {
      fetchSeriesDetails();
    }, 2000);

    // if ("caches" in window) {
    //   caches.keys().then((names) => {
    //     // Delete all the cache files
    //     names.forEach((name) => {
    //       caches.delete(name);
    //     });
    //   });
    // }
  }, [props.saveIac.updateModalId]);

  useEffect(() => {
    if (!isEmpty(props.aws.AwsData) && !markerImg) {
      let timer1 = setTimeout(() => {
        props.showLoading('Loading');
        // let tmp = Object.assign([], tmpAwsArrayMarker);
        // let data = {
        //   id: uniqueId(),
        //   get_c_code_image: {
        //     id: uniqueId(),
        //     c_code_image: props.aws.AwsData?.url,
        //     ccodeimagekey: "",
        //     filename: uniqueId(),
        //   },
        // };

        // tmp.push(data);
        // setTmpAwsArrayMarker(tmp);
        setMarkerImg(props.aws.AwsData);
      }, 4000);
      return () => {
        clearTimeout(timer1);
        props.hideLoading();
      };
    }
    init();
  }, [
    uploadedThreeDModel.length,
    data.markerImageObj,
    markerImg,
    props.aws.AwsData,
  ]);

  useEffect(() => {
    if (!isEmpty(props.updateIac.UiacData)) {
      updateIacModal(props.updateIac.UiacData);
    }
  }, [props.updateIac.UiacData]);

  useEffect(() => {
    setSeriesId(props.series.seriesId);
    setBookId(props.book.bookId);
    setChapterId(props.chapter.chapterId);
  }, [props.series.seriesId, props.book.bookId, props.chapter.chapterId]);

  const updateIacModal = (e) => {
    // console.log('e', e);
    let convertAxis = Object.assign([], e?.getcontentgallery);
    if (convertAxis) {
      for (let i = 0; i < convertAxis.length; i++) {
        convertAxis[i].position = {
          x: Number(convertAxis[i].position.x),
          y: Number(convertAxis[i].position.y),
          z: Number(convertAxis[i].position.z),
        };
        convertAxis[i].rotation = {
          x: Number(convertAxis[i].rotation.x),
          y: Number(convertAxis[i].rotation.y),
          z: Number(convertAxis[i].rotation.z),
        };
        convertAxis[i].scale = {
          x: Number(convertAxis[i].scale.x),
          y: Number(convertAxis[i].scale.y),
          z: Number(convertAxis[i].scale.z),
        };
      }
    }

    setSeriesId(e?.series_id);
    setChapterId(e?.chapter_id);
    setBookId(e?.book_id);
    props.setSeriesId(e?.series_id);
    props.setBookId(e?.book_id);
    props.setChapterId(e?.chapter_id);

    props.fetchBookDetails(e?.series_id);
    props.fetchChapterDetails(e?.book_id);
    props.fetchFilesDetails(e?.chapter_id);
    setIacModelName(e.iac_name);

    // console.log('at time of update convertAxis', convertAxis);
    setUploadedThreeDModel(convertAxis);
    let tmp = Object.assign({}, data);
    // console.log('at time of update tmp', tmp);
    tmp.ccodeimagekey = e?.get_c_code_image.ccodeimagekey || '';
    tmp.filename = e?.get_c_code_image.c_code_image || '';
    setData(tmp);
    setMarkerImg({
      url: e?.get_c_code_image?.c_code_image || '',
      ccodeimagekey: e?.get_c_code_image?.ccodeimagekey || '',
    });
  };

  const searchUrlisThere = () => {
    setUpdateIacId('');
    if (props.location.search.includes('hash')) {
      let hashId = props.location.search.split('&')[0].split('=').pop();
      localStorage.removeItem('auth');
      localStorage.setItem('hash', hashId);
      props.fetchTokenDetailsHash(hashId);
    }
    let id = props.location.search.split('&').pop().split('=').pop();
    setTimeout(() => {
      props.fetchUIacDetails(id);
    }, 2000);
    setUpdateIacId(id);
  };

  const init = () => {
    // Reset State First
    LoadRest();

    // SCENE
    LoadScene();

    // Gridhelper markerImg
    // !markerImg?.url && LoadGrid();
    LoadGrid();

    // CAMERA
    LoadCamera();

    // RENDERER
    LoadRenderer();

    // CONTROLS
    LoadControl();

    // FLOOR
    LoadFloor();

    // SET LIGTH
    LoadPointLight();

    // LOAD 3D MODEL
    LoadModel();

    mouse = new THREE.Vector3();
    document.addEventListener('click', mouseUpHandler, false);

    window.addEventListener(
      'resize',
      () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        renderer.setSize(width / 1.6, height / 1.45);
      },
      false
    );
    animate();
  };

  // Load Reset Function is use to reset all temporary variables
  const LoadRest = () => {
    setData((prevState) => ({
      ...data,
      iacContentObject: {
        ...data.iacContentObject,
        iacName: data.iacContentObject.iacName,
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      markerImageObj: prevState.markerImageObj,
    }));
  };

  // It is use to add scene/screen/background
  const LoadScene = () => {
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xa9a9a9);

    // let grid = new THREE.GridHelper(100, 100);
    // scene.add(grid);
  };

  // It will add camera and its positions to the scene
  const LoadCamera = () => {
    camera = new THREE.PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight,
      0.01,
      1000
    );
    camera.position.set(0, 3, 3);
    scene.add(camera);
  };
  // const LoadCamera = () => {
  //   // camera = new THREE.PerspectiveCamera(
  //   //   VIEW_ANGLE,
  //   //   window.innerWidth / window.innerHeight,
  //   //   NEAR,
  //   //   FAR
  //   // );
  //   // camera.position.set(0, 660, 850);
  //   // camera.position.z = 1.5;
  //   // camera.lookAt(0, 200, 0);
  //   camera = new THREE.PerspectiveCamera(
  //     30,
  //     window.innerWidth / window.innerHeight,
  //     0.0001,
  //     5000
  //   );
  //   camera.position.set(0, 3, 0);
  //   camera.lookAt(0, 200, 0);
  //   scene.add(camera);
  // };

  // It will add Point Light to the canvas

  const LoadPointLight = () => {
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    camera.add(pointLight);

    const keyLight = new THREE.DirectionalLight(
      new THREE.Color('hsl(30, 100%, 75%)'),
      1.0
    );
    keyLight.position.set(-100, 0, 100);
    scene.add(keyLight);

    const fillLight = new THREE.DirectionalLight(
      new THREE.Color('hsl(240, 100%, 75%)'),
      0.75
    );
    fillLight.position.set(100, 0, 100);
    scene.add(fillLight);

    const backLight = new THREE.DirectionalLight(0xffffff, 1.0);
    backLight.position.set(100, 0, -100).normalize();
    scene.add(backLight);

    const light = new THREE.HemisphereLight(0xffffff, 0x444444);
    light.position.set(0, 200, 0);
    scene.add(light);
  };

  // handler called when mesh object will change
  const changeStateForMesh = (event) => {
    const activeObjectNameUuid = {
      name: event.name,
      uuid: event.uuid,
    };

    const tmp = Object.assign([], uploadedThreeDModel);

    for (let j = 0; j < tmp.length; j++) {
      if (tmp[j].uuid === event.uuid) {
        let position = {
          x: event.position.x || 0,
          y: event.position.y || 0,
          z: event.position.z || 0,
        };
        let rotation = {
          x: event.rotation.x || 0,
          y: event.rotation.y || 0,
          z: event.rotation.z || 0,
        };
        let scale = {
          x: event.scale.x || 1,
          y: event.scale.y || 1,
          z: event.scale.z || 1,
        };
        tmp[j].position = position;
        tmp[j].rotation = rotation;
        tmp[j].scale = scale;
      }
    }

    // console.log('On Change changeStateForMesh', event);
    setData({
      ...data,
      iacContentObject: {
        iacName: event.name,
        position: {
          // ...data.iacContentObject.position,
          x: event.position.x,
          y: event.position.y,
          z: event.position.z,
        },
        rotation: {
          // ...data.iacContentObject.rotation,
          x: event.rotation.x,
          y: event.rotation.y,
          z: event.rotation.z,
        },
        scale: {
          x: event.scale.x,
          y: event.scale.y,
          z: event.scale.z,
        },
      },
    });

    activeObjectuuid = activeObjectNameUuid;
    setUploadedThreeDModel(tmp);
  };

  // It will fetch id Canvas from div and add to scene
  // It become your view screen
  const LoadRenderer = () => {
    renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    container = document.querySelector('#Canvas');
    if (container && container.childElementCount > 0) {
      container.innerHTML = '';
    }
    container.appendChild(renderer.domElement);
  };

  // It will handle Mouse Click Event
  const mouseUpHandler = (event) => {
    event.preventDefault();
    let rect = renderer.domElement.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    let vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
    vector = vector.unproject(camera);
    const raycaster = new THREE.Raycaster(
      camera.position,
      vector.sub(camera.position).normalize()
    );
    // raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(meshs, true);
    if (intersects.length > 0) {
      for (const intersectObj of intersects) {
        for (const mesh of meshs) {
          if (intersectObj.object.uuid === mesh.uuid) {
            changeStateForMesh(intersectObj.object);
            transformControl.attach(intersectObj.object);
          }
        }
      }
    }
  };

  // It will add Orbit control to the scene
  // We can move scene using mouse
  const LoadControl = () => {
    orbitControl = new OrbitControls(camera, renderer.domElement);
    orbitControl.enableKeys = false;

    transformControl = new TransformControls(camera, renderer.domElement);
    transformControl.setSize(1);
    transformControl.addEventListener('change', renderCanvas);
    transformControl.addEventListener('dragging-changed', (event) => {
      changeDragPropertiesValue();
      orbitControl.enabled = !event.value;
    });
    if (control.isTranslate) transformControl.setMode('translate');
    if (control.isRotate) transformControl.setMode('rotate');
    if (control.isScale) transformControl.setMode('scale');

    scene.add(transformControl);
  };

  // It will give grid view to the mesh
  const LoadGrid = () => {
    gridControl = new THREE.GridHelper(100, 100);
    // gridControl = new THREE.GridHelper(1, 5);
    scene.add(gridControl);
  };

  const changeDragPropertiesValue = () => {
    if (meshs.length) {
      meshs.map((obj) => {
        if (obj.uuid === activeObjectuuid.uuid) {
          changeStateForMesh(obj);
        }
        return false;
      });
    }
  };

  const MarkerNotLoad = (url) => {
    const loader = new THREE.TextureLoader();
    loader.load(url, (img) => {
      img.needsUpdate = true;
      img.crossOrigin = '';
      const floorMaterial = new THREE.MeshBasicMaterial({
        map: img,
        side: THREE.DoubleSide,
      });
      const floorGeometry = new THREE.PlaneGeometry(
        img.image.width,
        img.image.height
      );
      floor = new THREE.Mesh(floorGeometry, floorMaterial);
      floor.geometry.needsUpdate = true;
      // floor.position.y = -10;
      floor.rotation.x = -Math.PI / 2;
      scene.add(floor);
    });
  };

  // It will add the mesh to the scene
  const LoadFloor = () => {
    let url = markerImg?.url;
    // let url =
    //   "https://dummyimage.com/377.9527559055x377.9527559055/000/fff";

    const loader = new THREE.TextureLoader();
    loader.setCrossOrigin('');
    const floorTexture = loader.load(url, (img) => {
      img.needsUpdate = true;
      floor.scale.set(1.0, img.image.height / img.image.width, 1.0);
    });
    floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
    const floorMaterial = new THREE.MeshBasicMaterial({
      map: floorTexture,
      side: THREE.DoubleSide,
    });
    const floorGeometry = new THREE.PlaneGeometry(1, 1, 1);
    floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.name = 'Floor';
    floor.geometry.computeBoundingBox();
    floor.geometry.needsUpdate = true;
    floor.position.y = 0;
    floor.rotation.set(
      90 * MathUtils.DEG2RAD,
      180 * MathUtils.DEG2RAD,
      180 * MathUtils.DEG2RAD
    );
    scene.add(floor);
    // if (!url) {
    //   const floorTexture = loader.load(url, (img) => {
    //     img.needsUpdate = true;
    //     img.crossOrigin = '';
    //     floor.scale.set(1.0, img.image.height / img.image.width, 1.0);
    //   });
    //   floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
    //   const floorMaterial = new THREE.MeshBasicMaterial({
    //     map: floorTexture,
    //     side: THREE.DoubleSide,
    //   });
    //   const floorGeometry = new THREE.PlaneGeometry(1, 1);
    //   floor = new THREE.Mesh(floorGeometry, floorMaterial);
    //   floor.geometry.computeBoundingBox();
    //   floor.geometry.needsUpdate = true;
    //   // floor.position.y = -10;
    //   floor.rotation.x = -Math.PI / 2;
    //   scene.add(floor);
    // } else {
    //   loader.load(
    //     url,
    //     (img) => {
    //       img.needsUpdate = true;
    //       img.crossOrigin = '';
    //       const floorMaterial = new THREE.MeshBasicMaterial({
    //         map: img,
    //         side: THREE.DoubleSide,
    //       });
    //       // const floorGeometry = new THREE.PlaneGeometry(
    //       //   img.image.width,
    //       //   img.image.height
    //       // );
    //       const floorGeometry = new THREE.PlaneGeometry(
    //         1,
    //         img.image.height / img.image.width
    //       );
    //       floor = new THREE.Mesh(floorGeometry, floorMaterial);
    //       floor.geometry.needsUpdate = true;
    //       // floor.position.y = -10;
    //       floor.rotation.x = -Math.PI / 2;
    //       scene.add(floor);
    //     },
    //     undefined,
    //     (err) => {
    //       console.log('err');
    //       MarkerNotLoad(url);
    //     }
    //   );
    // }
  };

  

  // It will Load 3d model to the mesh
  const LoadModel = () => {
    meshs = [];
    console.log(uploadedThreeDModel.length);
    if (uploadedThreeDModel.length > 0) {

      var k = uploadedThreeDModel.length-1;
      const manager = new THREE.LoadingManager();
      const fbxLoader = new FBXLoader();
      const texture = new THREE.Texture();
      const loader = new THREE.ImageLoader(manager);
      const loadedMaterials = [];

      const mtlLoader = new MTLLoader(manager);
      if (uploadedThreeDModel[k].mtl.url == null){
        uploadedThreeDModel[k].mtl.url = "";
      }
      mtlLoader.load(uploadedThreeDModel[k].mtl.url, (materials) => {
        materials.preload();
        loadedMaterials.push(materials);
      });

      for (let i = 0; i < uploadedThreeDModel[k].arrOfTexture.length; i++) {
        loader.load(
          uploadedThreeDModel[k].arrOfTexture[i].ar_content_files,
          (img) => {
            if (img) {
              texture.image = img;
              texture.mapping = THREE.UVMapping;
              texture.needsUpdate = true;
            }
          }
        );
      }
      manager.onLoad = () => {
        fbxLoader.load(
          uploadedThreeDModel[k].url.url,
          (fbx) => {
            // fbx.traverse((child) => {
            //   if (child instanceof THREE.Mesh) {
            //     child.material =
            //       child.material || new THREE.MeshPhongMaterial();
            //     child.material['map'] = texture;
            //   }
            // });
            console.log("Model loading  ",uploadedThreeDModel[k]);
            fbx.receiveShadow = true;
            fbx.castShadow = true;
           // console.log("Uploaded model  Data ",uploadedThreeDModel[k]);
            //console.log("Only name : ",uploadedThreeDModel[k].url.name.name)
            fbx.name = 'FBX'//uploadedThreeDModel[k].url.name.name'';
            fbx.matrixAutoUpdate = true;
            fbx.updateMatrix();
            scene.add(fbx);
            const geometry = new THREE.Geometry().fromBufferGeometry(
              fbx.children[0]['geometry']
            );
            const cylBleu = new THREE.MeshNormalMaterial({
              transparent: true,
              opacity: 0,
              side: THREE.DoubleSide,
            });
            newMesh = new THREE.Mesh(geometry, cylBleu);

            if (uploadedThreeDModel[k]) {
              newMesh.position.set(
                uploadedThreeDModel[k].position.x || 0,
                uploadedThreeDModel[k].position.y || 0,
                uploadedThreeDModel[k].position.z || 0
              );
              newMesh.rotation.set(
                uploadedThreeDModel[k].rotation.x * MathUtils.DEG2RAD || 0,
                uploadedThreeDModel[k].rotation.y * MathUtils.DEG2RAD || 0,
                uploadedThreeDModel[k].rotation.z * MathUtils.DEG2RAD || 0
              );
              newMesh.scale.set(
                uploadedThreeDModel[k].scale.x || 1,
                uploadedThreeDModel[k].scale.y || 1,
                uploadedThreeDModel[k].scale.z || 1
              );
            } else {
              newMesh.scale.set(1, 1, 1);
            }
            newMesh.geometry.computeBoundingBox();
            const sizeOfObject = newMesh.geometry.boundingBox.clone();

            newMesh.add(fbx);
            newMesh.name = uploadedThreeDModel[k].url.name.name;
            newMesh.uuid = 12345;
           
            uploadedThreeDModel[k].uuid = newMesh.uuid;
            scene.add(newMesh);
            meshs.push(newMesh);

            transformControl.attach(newMesh);
            changeStateForMesh(newMesh);
          },
          (xhr) => {
            // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
            if ((xhr.loaded / xhr.total) * 100 === 100) {
              props.hideLoading();
            } else {
              props.showLoading('Loading');
            }
          },
          (error) => {
            // console.log("An error happened");
          }
        );
      };
  
/*
      for (let k = 0; k < uploadedThreeDModel.length; k++) {
        let Extention = uploadedThreeDModel[k].url.url
          .split('/')
          .pop()
          .split('?')[0]
          .split('.')
          .pop()
          .toLowerCase();
        if (uploadedThreeDModel[k].modelType === 'fbx' || Extention === 'fbx') {
           console.log('uploadedThreeDModel[k]', uploadedThreeDModel[k]);
          const manager = new THREE.LoadingManager();
          const fbxLoader = new FBXLoader();
          const texture = new THREE.Texture();
          const loader = new THREE.ImageLoader(manager);
          const loadedMaterials = [];

          const mtlLoader = new MTLLoader(manager);
          if (uploadedThreeDModel[k].mtl.url == null){
            uploadedThreeDModel[k].mtl.url = "";
          }
          mtlLoader.load(uploadedThreeDModel[k].mtl.url, (materials) => {
            materials.preload();
            loadedMaterials.push(materials);
          });

          for (let i = 0; i < uploadedThreeDModel[k].arrOfTexture.length; i++) {
            loader.load(
              uploadedThreeDModel[k].arrOfTexture[i].ar_content_files,
              (img) => {
                if (img) {
                  texture.image = img;
                  texture.mapping = THREE.UVMapping;
                  texture.needsUpdate = true;
                }
              }
            );
          }
          manager.onLoad = () => {
            fbxLoader.load(
              uploadedThreeDModel[k].url.url,
              (fbx) => {
                // fbx.traverse((child) => {
                //   if (child instanceof THREE.Mesh) {
                //     child.material =
                //       child.material || new THREE.MeshPhongMaterial();
                //     child.material['map'] = texture;
                //   }
                // });
                console.log("Model loading  ",uploadedThreeDModel[k]);
                fbx.receiveShadow = true;
                fbx.castShadow = true;
               // console.log("Uploaded model  Data ",uploadedThreeDModel[k]);
                //console.log("Only name : ",uploadedThreeDModel[k].url.name.name)
                fbx.name = 'FBX'//uploadedThreeDModel[k].url.name.name'';
                fbx.matrixAutoUpdate = true;
                fbx.updateMatrix();
                scene.add(fbx);
                const geometry = new THREE.Geometry().fromBufferGeometry(
                  fbx.children[0]['geometry']
                );
                const cylBleu = new THREE.MeshNormalMaterial({
                  transparent: true,
                  opacity: 0,
                  side: THREE.DoubleSide,
                });
                newMesh = new THREE.Mesh(geometry, cylBleu);

                if (uploadedThreeDModel[k]) {
                  newMesh.position.set(
                    uploadedThreeDModel[k].position.x || 0,
                    uploadedThreeDModel[k].position.y || 0,
                    uploadedThreeDModel[k].position.z || 0
                  );
                  newMesh.rotation.set(
                    uploadedThreeDModel[k].rotation.x * MathUtils.DEG2RAD || 0,
                    uploadedThreeDModel[k].rotation.y * MathUtils.DEG2RAD || 0,
                    uploadedThreeDModel[k].rotation.z * MathUtils.DEG2RAD || 0
                  );
                  newMesh.scale.set(
                    uploadedThreeDModel[k].scale.x || 1,
                    uploadedThreeDModel[k].scale.y || 1,
                    uploadedThreeDModel[k].scale.z || 1
                  );
                } else {
                  newMesh.scale.set(1, 1, 1);
                }
                newMesh.geometry.computeBoundingBox();
                const sizeOfObject = newMesh.geometry.boundingBox.clone();

                newMesh.add(fbx);
                newMesh.name = uploadedThreeDModel[k].url.name.name;
                newMesh.uuid = 12345;
               
                uploadedThreeDModel[k].uuid = newMesh.uuid;
                scene.add(newMesh);
                meshs.push(newMesh);

                transformControl.attach(newMesh);
                changeStateForMesh(newMesh);
              },
              (xhr) => {
                // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                if ((xhr.loaded / xhr.total) * 100 === 100) {
                  props.hideLoading();
                } else {
                  props.showLoading('Loading');
                }
              },
              (error) => {
                // console.log("An error happened");
              }
            );
          };

          // fbxLoader.load(uploadedThreeDModel[k].url.url, (fbx) => {
          //   fbx.receiveShadow = true;
          //   fbx.castShadow = true;
          //   fbx.name = uploadedThreeDModel[k].url.name.name;
          //   fbx.matrixAutoUpdate = true;
          //   fbx.updateMatrix();
          //   scene.add(fbx);
          //   const geometry = new THREE.Geometry().fromBufferGeometry(
          //     fbx.children[0]['geometry']
          //   );
          //   const cylBleu = new THREE.MeshNormalMaterial({
          //     transparent: true,
          //     opacity: 0,
          //     side: THREE.DoubleSide,
          //   });
          //   newMesh = new THREE.Mesh(geometry, cylBleu);

          //   if (uploadedThreeDModel[k]) {
          //     newMesh.position.set(
          //       uploadedThreeDModel[k].position.x || 0,
          //       uploadedThreeDModel[k].position.y || 0,
          //       uploadedThreeDModel[k].position.z || 0
          //     );
          //     newMesh.rotation.set(
          //       uploadedThreeDModel[k].rotation.x || 0,
          //       uploadedThreeDModel[k].rotation.y || 0,
          //       uploadedThreeDModel[k].rotation.z || 0
          //     );
          //     newMesh.scale.set(
          //       uploadedThreeDModel[k].scale.x || 1,
          //       uploadedThreeDModel[k].scale.y || 1,
          //       uploadedThreeDModel[k].scale.z || 1
          //     );
          //   } else {
          //     newMesh.scale.set(1, 1, 1);
          //   }
          //   newMesh.geometry.computeBoundingBox();

          //   newMesh.add(fbx);
          //   newMesh.name = uploadedThreeDModel[k].url.name.name;

          //   uploadedThreeDModel[k].uuid = newMesh.uuid;
          //   scene.add(newMesh);
          //   meshs.push(newMesh);

          //   transformControl.attach(newMesh);
          //   changeStateForMesh(newMesh);
          // });
        }
        if (uploadedThreeDModel[k].modelType === 'obj' || Extention === 'obj') {
          const objLoader = new OBJLoader();
          const texture = new THREE.Texture();
          const manager = new THREE.LoadingManager();
          const loader = new THREE.ImageLoader(manager);
          let loadedMaterials = [];

          manager.onLoad = () => {
            if (loadedMaterials.length > 0) {
              objLoader.setMaterials(loadedMaterials[0]);
              objLoader.load(
                uploadedThreeDModel[k].url.url,
                (object) => {
                  object.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                      child.material =
                        child.material || new THREE.MeshPhongMaterial();
                      child.material['map'] = texture;
                    }
                  });
                  object.receiveShadow = true;
                  object.castShadow = true;
                  object.name = uploadedThreeDModel[k].url.name.name;
                  object.matrixAutoUpdate = true;
                  object.updateMatrix();
                  scene.add(object);
                  const geometry = new THREE.Geometry().fromBufferGeometry(
                    object.children[0]['geometry']
                  );
                  const cylBleu = new THREE.MeshNormalMaterial({
                    transparent: true,
                    opacity: 0,
                    side: THREE.DoubleSide,
                  });
                  newMesh = new THREE.Mesh(geometry, cylBleu);
                  if (uploadedThreeDModel[k]) {
                    newMesh.position.set(
                      uploadedThreeDModel[k].position.x || 0,
                      uploadedThreeDModel[k].position.y || 0,
                      uploadedThreeDModel[k].position.z || 0
                    );
                    newMesh.rotation.set(
                      uploadedThreeDModel[k].rotation.x || 0,
                      uploadedThreeDModel[k].rotation.y || 0,
                      uploadedThreeDModel[k].rotation.z || 0
                    );
                    newMesh.scale.set(
                      uploadedThreeDModel[k].scale.x || 1,
                      uploadedThreeDModel[k].scale.y || 1,
                      uploadedThreeDModel[k].scale.z || 1
                    );
                  } else {
                    newMesh.scale.set(1, 1, 1);
                  }

                  newMesh.geometry.computeBoundingBox();
                  const sizeOfObject = newMesh.geometry.boundingBox.clone();
                  newMesh.add(object);
                  newMesh.name = uploadedThreeDModel[k].url.name.name;

                  uploadedThreeDModel[k].uuid = newMesh.uuid;
                  scene.add(newMesh);
                  meshs.push(newMesh);

                  transformControl.attach(newMesh);
                  changeStateForMesh(newMesh);
                },
                (xhr) => {
                  // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                  if ((xhr.loaded / xhr.total) * 100 === 100) {
                    props.hideLoading();
                  } else {
                    props.showLoading('Loading');
                  }
                },
                (error) => {
                  // console.log("An error happened");
                }
              );
            }
          };

          for (let i = 0; i < uploadedThreeDModel[k].arrOfTexture.length; i++) {
            loader.load(
              uploadedThreeDModel[k].arrOfTexture[i].ar_content_files,
              (img) => {
                if (img) {
                  texture.image = img;
                  texture.mapping = THREE.UVMapping;
                  texture.needsUpdate = true;
                }
              }
            );
          }

          // for multiple mtl files loader
          const mtlLoader = new MTLLoader(manager);
          mtlLoader.load(uploadedThreeDModel[k].mtl.url || '', (materials) => {
            materials.preload();
            loadedMaterials.push(materials);
          });
        }
      }
    
    */
    }
  };

  // It will update the matrix
  // It will use to load all the things in scene
  const animate = () => {
    requestAnimationFrame(animate);
    renderCanvas();
    update();
  };

  const update = () => {
    orbitControl.update();
  };

  const resizeRendererToDisplaySize = (renderer) => {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  };

  // It will Load scene and camera
  const renderCanvas = () => {
    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }
    renderer.render(scene, camera);
  };

  // It is use to update x,y,z  co-ordinate from Input fileds
  const changeAxisValue = (property, axis, value) => {
    if (property === 'rotation') {
      setData({
        ...data,
        iacContentObject: {
          ...data.iacContentObject,
          [property]: {
            ...data.iacContentObject[property],
            [axis]: Number(value),
          },
        },
      });
    } else {
      setData({
        ...data,
        iacContentObject: {
          ...data.iacContentObject,
          [property]: {
            ...data.iacContentObject[property],
            [axis]: Number(value),
          },
        },
      });
    }
    meshs.map((obj, index) => {
      if (obj.uuid === activeObjectuuid.uuid) {
        const tmp = Object.assign([], uploadedThreeDModel);

        for (let j = 0; j < tmp.length; j++) {
          if (tmp[j].uuid === activeObjectuuid.uuid) {
            tmp[j][property][axis] = Number(value);
          }
          setUploadedThreeDModel(tmp);
        }
        obj[property][axis] = Number(value);
        return obj;
      }
    });
  };

  // It will use to toggle property of isTranslate,isRotate,isScale
  const selectContolButton = (selectedPropertyTypes) => {
    if (selectedPropertyTypes === 'isTranslate') {
      setControl({
        isTranslate: true,
        isRotate: false,
        isScale: false,
      });
      transformControl.setMode('translate');
    }
    if (selectedPropertyTypes === 'isRotate') {
      setControl({
        isTranslate: false,
        isRotate: true,
        isScale: false,
      });
      transformControl.setMode('rotate');
    }
    if (selectedPropertyTypes === 'isScale') {
      setControl({
        isTranslate: false,
        isRotate: false,
        isScale: true,
      });
      transformControl.setMode('scale');
    }
  };

  // Select Book Series
  const selectBookSeries = (data) => {
    props.ResetBookId();
    props.ResetChapterId();
    // setBookId();
    // setChapterId();
    props.setSeriesId(data.target.value);
    props.fetchBookDetails(data.target.value);
  };

  // Select Book Name
  const selectBookName = (data) => {
    props.ResetChapterId();
    props.setBookId(data.target.value);
    props.fetchChapterDetails(data.target.value);
  };

  // Select Book Chapter
  const selectBookChapter = (data) => {
    props.setChapterId(data.target.value);
    props.fetchFilesDetails(data.target.value);
  };

  // Select or Upload Marker
  const saveMarkerImage = (e) => {
    if (e.id) {
      setMarkerImg('');
      // let tmp = Object.assign({}, data);
      // tmp.ccodeimagekey = e?.get_c_code_image.ccodeimagekey;
      // tmp.filename = e?.get_c_code_image.c_code_image;
      // setData(tmp);
      let tmpImg = {};
      tmpImg.url = e?.get_c_code_image.c_code_image;
      tmpImg.ccodeimagekey = e?.get_c_code_image.ccodeimagekey;
      setMarkerImg(tmpImg);
    } else {
      setMarkerImg('');
      props.fetchAddAwsDetails(e);
    }
    setModalMarker(!modalMarker);
  };

  // Store Iac Model Name
  const handleName = (e) => {
    setIacModelName(e.target.value);
  };

  // Handle Final IAC is submitting
  const handleSaveIac = () => {
    // let convertAxis = Object.assign([], uploadedThreeDModel);
    let convertAxis = JSON.parse(JSON.stringify(uploadedThreeDModel));

    if (convertAxis) {
      for (let i = 0; i < convertAxis.length; i++) {
        convertAxis[i].position = {
          x: Number(convertAxis[i].position.x.toFixed(3)),
          y: Number(convertAxis[i].position.y.toFixed(3)),
          z: Number(convertAxis[i].position.z.toFixed(3)),
        };
        convertAxis[i].rotation = {
          x: Number(convertAxis[i].rotation.x * MathUtils.RAD2DEG),
          y: Number(convertAxis[i].rotation.y * MathUtils.RAD2DEG),
          z: Number(convertAxis[i].rotation.z * MathUtils.RAD2DEG),
        };
        convertAxis[i].scale = {
          x: Number(convertAxis[i].scale.x.toFixed(3)),
          y: Number(convertAxis[i].scale.y.toFixed(3)),
          z: Number(convertAxis[i].scale.z.toFixed(3)),
        };
      }
    }

    let newconvertAxis = Object.assign([], convertAxis);
    let saveIac = {
      marker_image_id:
        props.modelData?.modelData?.[0]?.get_c_code_image?.id || null,
      ar_model_id:
        props.modelData?.modelData?.[0]?.getcontentgallery?.id || null,
      series_id: seriesId,
      book_id: bookId,
      chapter_id: chapterId,
      get_c_code_image: {
        c_code_image: markerImg?.url || markerImg,
        ccodeimagekey: markerImg?.ccodeimagekey || '',
      },
      getcontentgallery: newconvertAxis,
      iac_name: iacModelName,
    };
    // console.log('saveIac', saveIac);
    if (updateIacId) {
      props.fetchUpdateIacDetails(saveIac, updateIacId);
    } else {
      props.fetchAddIacDetails(saveIac);
    }
  };

  // Select or Upload Model
  const saveModel = (e) => {
    let tmp = Object.assign([], uploadedThreeDModel);
    // tmp = [...uploadedThreeDModel];
    tmp.push(e);
    setUploadedThreeDModel(tmp);
    setModel3d(!model3d);
    props.resetAwsDetails();
  };

  const HandleResetModal = () => {
    props.history.push({
      pathname: '/',
      search: '',
    });
    window.location.reload();
    props.ModalCloseHandler();
  };

  const HandleContinueModal = () => {
    let hash = localStorage.getItem('hash');

    if (props.location.search) {
    } else {
      if (hash) {
        props.history.push({
          pathname: '/',
          search: `?hash=${hash}&id=${props.saveIac.updateModalId}`,
        });
      } else {
        props.history.push({
          pathname: '/',
          search: `?id=${props.saveIac.updateModalId}`,
        });
      }
    }
    window.location.reload();
    props.ModalCloseHandler();
  };

  // console.log('mesh', meshs);
  // console.log('uploadedThreeDModel', uploadedThreeDModel);

  return (
    <>
      <div className='main-box'>
        <div>
          <select
            id='bookseries'
            name='bookseries'
            style={{ width: '15%', margin: '5px' }}
            defaultValue={''}
            value={seriesId}
            className='custom-select form-control required-filed'
            onChange={selectBookSeries}
          >
            <option value='' disabled>
              Book Series
            </option>
            {props.series.isLoading && (
              <option value='Loading' disabled>
                {props.series.title}
              </option>
            )}
            {props.series.seriesData &&
              props.series.seriesData?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
          </select>
          <select
            id='bookname'
            name='bookname'
            style={{ width: '15%', margin: '5px' }}
            defaultValue={''}
            value={bookId}
            className='custom-select form-control required-filed'
            onChange={selectBookName}
            disabled={!seriesId}
          >
            <option value='' disabled>
              Book Name
            </option>
            {props.book.isLoading && (
              <option value='Loading' disabled>
                {props.book.title}
              </option>
            )}
            {props.book.bookData &&
              props.book.bookData?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
          </select>
          <select
            id='bookchapter'
            name='bookchapter'
            style={{ width: '15%', margin: '5px' }}
            defaultValue={''}
            value={chapterId}
            className='custom-select form-control required-filed'
            onChange={selectBookChapter}
            disabled={!bookId}
          >
            <option value='' disabled>
              Book Chapter
            </option>
            {props.chapter.isLoading && (
              <option value='Loading' disabled>
                {props.chapter.title}
              </option>
            )}
            {props.chapter.chapterData &&
              props.chapter.chapterData?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
          </select>
        </div>
        <div className='row main-box'>
          <button
            className='btn btn-primary '
            type='button'
            onClick={() => {
              props.fetchFilesDetails(chapterId);
              setModalMarker(!modalMarker);
            }}
            disabled={
              // props.series.seriesDisable ||
              // props.book.bookDisable ||
              // props.chapter.chapterDisable
              !seriesId || !bookId || !chapterId
            }
          >
            <span>Choose Marker</span>
          </button>

          <button
            className='btn btn-primary '
            type='button'
            onClick={() => {
              props.fetchFilesDetails(chapterId);
              setModel3d(!model3d);
            }}
            disabled={
              // props.series.seriesDisable ||
              // props.book.bookDisable ||
              // props.chapter.chapterDisable
              !seriesId || !bookId || !chapterId
            }
          >
            <span> Choose 3D Model</span>
          </button>
        </div>

        <div className='row'>
          <div className='col-8'>
            <div
              style={{
                border: '1px grey solid',
                padding: '10px',
                marginTop: '5px',
              }}
            >
              {/*Canvas is load on this div with #Canvas  */}
              <div id='Canvas' className='threejs-z' />

              <div className='editor-btn'>
                <button
                  className={`editor-cst-btn  ${
                    control.isTranslate === true ? 'active' : ''
                  }`}
                  onClick={() => selectContolButton('isTranslate')}
                >
                  Translate
                </button>
                <button
                  className={`editor-cst-btn ${
                    control.isRotate === true ? 'active' : ''
                  }`}
                  onClick={() => selectContolButton('isRotate')}
                >
                  Rotate
                </button>
                <button
                  className={`editor-cst-btn  ${
                    control.isScale === true ? 'active' : ''
                  }`}
                  onClick={() => selectContolButton('isScale')}
                >
                  Scale
                </button>
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className='row'>
              {/*We can save marker and 3d model with name */}
              <input
                type='text'
                value={iacModelName}
                className='form-control main-box mt-0'
                placeholder='Enter Image Augmentation name'
                onChange={(e) => handleName(e)}
              />
            </div>
            {/*We can Enter x,y,z co-ordinate from here  */}
            <div
              style={{
                border: '1px grey solid',
                padding: '10px',
                marginTop: '5px',
              }}
            >
              <div className='label'>Position</div>
              <Axis
                x={data.iacContentObject.position.x.toFixed(3)}
                y={data.iacContentObject.position.y.toFixed(3)}
                z={data.iacContentObject.position.z.toFixed(3)}
                setX={(value) => changeAxisValue('position', 'x', value)}
                setY={(value) => changeAxisValue('position', 'y', value)}
                setZ={(value) => changeAxisValue('position', 'z', value)}
              />
              <div className='label'>Rotation</div>
              <Axis
                x={data.iacContentObject.rotation.x * MathUtils.RAD2DEG}
                y={data.iacContentObject.rotation.y * MathUtils.RAD2DEG}
                z={data.iacContentObject.rotation.z * MathUtils.RAD2DEG}
                setX={(value) =>
                  changeAxisValue('rotation', 'x', value * (Math.PI / 180))
                }
                setY={(value) =>
                  changeAxisValue('rotation', 'y', value * (Math.PI / 180))
                }
                setZ={(value) =>
                  changeAxisValue('rotation', 'z', value * (Math.PI / 180))
                }
              />
              <div className='label'>Scale</div>
              <Axis
                x={data.iacContentObject.scale.x.toFixed(2)}
                y={data.iacContentObject.scale.y.toFixed(2)}
                z={data.iacContentObject.scale.z.toFixed(2)}
                setX={(value) => changeAxisValue('scale', 'x', value)}
                setY={(value) => changeAxisValue('scale', 'y', value)}
                setZ={(value) => changeAxisValue('scale', 'z', value)}
              />
              <div className='row'>
                <button
                  type='button'
                  className='btn btn-success main-box'
                  style={{ width: '100%' }}
                  onClick={() => handleSaveIac()}
                  disabled={
                    // isEmpty(props.chapter.chapterId) ||
                    // isEmpty(props.series.seriesId) ||
                    // isEmpty(props.book.bookId) ||
                    isEmpty(iacModelName) || !seriesId || !bookId || !chapterId
                  }
                >
                  {updateIacId ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal after save or update data */}
      <Modal
        isOpen={props.saveIac.modalStatus}
        toggle={props.ModalCloseHandler}
      >
        <ModalHeader toggle={props.ModalCloseHandler}>AR Builder</ModalHeader>
        <ModalBody>Do you want to ?</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => HandleContinueModal()}>
            Continue
          </Button>
          <Button color='secondary' onClick={() => HandleResetModal()}>
            Reset
          </Button>
        </ModalFooter>
      </Modal>

      {/* Snackbar for display success and failed messages. */}
      <Snackbar
        open={props.toasterIac.isLoading}
        autoHideDuration={3000}
        onClose={() => props.hideToaster()}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={() => props.hideToaster()}
          severity={props.toasterIac.type}
        >
          {props.toasterIac.title}
        </MuiAlert>
      </Snackbar>

      {/*Use to toggle model for Marker / MARKER GALLERY */}
      {modalMarker && (
        <ContentGallery
          modalMarker={modalMarker}
          closeModal={() => {
            props.resetAwsDetails();
            setModalMarker(!modalMarker);
          }}
          save={(e) => saveMarkerImage(e)}
          data={props.modelData.modelData}
          // tmpData={tmpAwsArrayMarker}
          chapterId={chapterId}
        />
      )}
      {/*Use to toggle model for 3D Model / AR GALLARY */}
      {model3d && (
        <ModelGallery
          model3d={model3d}
          closeModal={() => {
            props.resetAwsDetails();
            setModel3d(!model3d);
          }}
          save={(e) => {
            saveModel(e);
          }}
          awsData={props.aws.AwsModalData}
          data={props.modelData.modelData}
          api={(e) => props.fetchModelAwsDetails(e)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    series: state.admin.SeriesReducer,
    book: state.admin.BookReducer,
    chapter: state.admin.ChapterReducer,
    modelData: state.admin.MarkerModelReducer,
    aws: state.admin.AwsReducer,
    saveIac: state.admin.SaveIacReducer,
    load: state.admin.LoadingReducer,
    updateIac: state.admin.UpdateIacReducer,
    toasterIac: state.admin.ToasterReducer,
  };
};

const mapDispatchToProps = {
  fetchSeriesDetails,
  fetchTokenDetails,
  setSeriesId,
  fetchBookDetails,
  setBookId,
  setChapterId,
  fetchChapterDetails,
  fetchFilesDetails,
  fetchAddAwsDetails,
  fetchAddIacDetails,
  fetchUAddIacDetails,
  fetchModelAwsDetails,
  fetchUpdateIacDetails,
  fetchUIacDetails,
  resetAwsDetails,
  ModalCloseHandler,
  setSaveIacDetails,
  hideToaster,
  ResetSeriesId,
  ResetBookId,
  ResetChapterId,
  showLoading,
  hideLoading,
  fetchTokenDetailsHash,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminController);
