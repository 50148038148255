import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { Router } from "react-router";
import RoutesConfig from "./routesConfig";
import history from "./utils/history";
import { applyMiddleware, createStore } from "redux";
import createReducer from "./rootReducer";
import thunk from "redux-thunk";

const App = () => {
  const store = createStore(createReducer(), applyMiddleware(thunk));

  return (
    <div className="App">
      <Provider store={store}>
        <Router history={history}>
          <RoutesConfig />
        </Router>
      </Provider>
    </div>
  );
};

export default App;
