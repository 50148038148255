import React from 'react';

const AppLoader = ({ title }) => (
  <div className="loading-overlay">
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube" />
      <div className="sk-cube2 sk-cube" />
      <div className="sk-cube4 sk-cube" />
      <div className="sk-cube3 sk-cube" />
    </div>
    <h2>{title}</h2>
  </div>
);

export default AppLoader;
