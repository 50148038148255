import {
  GET_SAVE_IAC_DETAIL,
  SAVE_IAC_DETAILS_ERROR,
  SET_SAVE_IAC_DETAIL,
  UPDATE_SAVE_IAC_DETAILS,
  MODAL_CLOSE,
} from "./SaveIacActions";
import { showToaster, hideToaster } from "../../Toaster/ToasterActions";

import { GET, POST, PUT } from "../../../services/rest.service";
import { headerConfig } from "../../../utils/constants";
import axios from "axios";

export const getSaveIacDetails = () => {
  return (dispatch) => {
    dispatch({
      type: GET_SAVE_IAC_DETAIL,
    });
  };
};

// set initial data of Iac details through an API
export const setSaveIacDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SAVE_IAC_DETAIL,
      payload: data,
    });
  };
};

// update new Iac details after successful updation
export const updateSaveIacDetails = (updatedData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SAVE_IAC_DETAILS,
      payload: updatedData,
    });
  };
};

// handler for all errors and inform to the store
export const SaveIacDetailsErrorHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_IAC_DETAILS_ERROR,
      payload: error,
    });
  };
};

// After save Success modal close can handle using this
export const ModalCloseHandler = (error) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CLOSE,
      payload: error,
    });
  };
};

/* SERVER-SIDE APIs */
/**
 * GET Iac DETAILS OF EMPLOYEE
 * @param id
 * @returns {Function}
 */
export const fetchIacDetails = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/Iacsbybook/${id}`;
    return axios
      .get(url, headerConfig())
      .then((res) => {
        dispatch(setSaveIacDetails(res.data));
        if (res.success === true || res.status === 200) {
          dispatch(showToaster("Success", "success"));
        } else {
          dispatch(showToaster("API Error", "error"));
        }
      })
      .catch((err) => {
        dispatch(SaveIacDetailsErrorHandler(err));
        dispatch(showToaster("API Error", "error"));
      });
  };
};

/**
 * Update details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchUpdateIacDetails = (payload, id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/update3dmodel/${id}`;
    let newHeader = {
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("auth")),
      },
    };
    return axios
      .post(url, payload, newHeader)
      .then((res) => {
        dispatch(setSaveIacDetails(res.data));
        if (res.success === true || res.status === 200) {
          dispatch(showToaster("Success", "success"));
        } else {
          dispatch(showToaster("API Error", "error"));
        }
      })
      .catch((err) => {
        dispatch(SaveIacDetailsErrorHandler(err));
        dispatch(showToaster("API Error", "error"));
      });
  };
};

/**
 * Add Iac details of employee
 * @param payload
 * @returns {Function}
 */
export const fetchAddIacDetails = (payload) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_PATH}/save3dmodel`;
    let newHeader = {
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("auth")),
      },
    };
    return axios
      .post(url, payload, newHeader)
      .then((res) => {
        dispatch(setSaveIacDetails(res.data));
        if (res.success === true || res.status === 200) {
          dispatch(showToaster("Success", "success"));
        } else {
          dispatch(showToaster("API Error", "error"));
        }
      })
      .catch((err) => {
        dispatch(SaveIacDetailsErrorHandler(err));
        dispatch(showToaster("API Error", "error"));
      });
  };
};
